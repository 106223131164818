import { useFormik } from 'formik';
import React from 'react';
import { URL_STORE } from '../../constance/urls';
import * as Yup from 'yup';
import { Checkbox, Dimmer, Form, Header, Icon, Image, Label, Loader, Menu, Message, Segment } from 'semantic-ui-react';
import { BankChoices, StoreModel, TaxTypeChoices } from '../../constance/models/store';
import useAddressForm from '../../hooks/useAddressForm';
import useDropdownItem from '../../hooks/useDropdownItem';
import { Toast } from '../../frameworks/Toast';
import * as _ from 'lodash';
import { GET, PUT } from '../../frameworks/HttpClient';
import CustomFileField from '../common/CustomFileField';
import { imageToGrayScale } from '../../frameworks/Util';
import CustomInputField from '../common/CustomInputField';
import StoreUsers from './StoreUsers';
import { Media } from '../../AppMedia';
import { useStoreContext } from '../../hooks/StoreProvider';


export default function StoreDetail(props) {
  const {
    target,
    isOfficer,
    readOnly,
  } = props;

  const { setting } = useStoreContext();
  const { staff } = setting;

  const formik = useFormik({
    validateOnChange: false,
    enableReinitialize: true,
    initialValues: StoreModel,
    validationSchema: Yup.object().shape({
      name: Yup.string().required('กรุณากรอกข้อมูล'),
      branch: Yup.string().notRequired().nullable(),
      tax_rate: Yup.string().required('กรุณากรอกข้อมูล'),
      tax_type: Yup.string().required('กรุณากรอกข้อมูล'),
      use_template_product: Yup.bool().required('กรุณากรอกข้อมูล'),
      use_cash_box: Yup.bool().required('กรุณากรอกข้อมูล'),
      use_printer: Yup.bool().required('กรุณากรอกข้อมูล'),
      promptpay_account_code: Yup.string().when(['bank', 'bank_account_name', 'bank_account_code'], {
        is: (bank, bank_account_name, bank_account_code) => !bank && !bank_account_name && !bank_account_code,
        then: Yup.string().required('กรุณากรอกข้อมูลธนาคารหรือบัญชีพร้อมเพย์').nullable()
      }).nullable(),
      bank: Yup.string().when('promptpay_account_code', {
        is: (promptpay_account_code) => !promptpay_account_code,
        then: Yup.string().required('กรุณากรอกข้อมูลธนาคารหรือบัญชีพร้อมเพย์').nullable()
      }).nullable(),
      bank_account_name: Yup.string().when('bank', {
        is: (bank) => bank,
        then: Yup.string().required('กรุณากรอกข้อมูล').nullable()
      }).nullable(),
      bank_account_code: Yup.string().when('bank', {
        is: (bank) => bank,
        then: Yup.string().required('กรุณากรอกข้อมูล').nullable()
      }).nullable(),
      address_no: Yup.string().required('กรุณากรอกข้อมูล').nullable(),
      sub_district: Yup.string().required('กรุณากรอกข้อมูล').nullable(),
      telephone: Yup.string().required('กรุณากรอกข้อมูล').nullable(),
      email: Yup.string().email('กรอกอีเมลให้ถูกต้อง').nullable(),
    }, ['bank', 'promptpay_account_code']),
    onSubmit: async (values) => {
      if(readOnly) {
        return;
      }
      setErrorMessages(null);
      setIsLoading(true);
      // Remove use_staff field
      delete values['use_staff']

      try {
        if (!file) {
          delete values['logo'];
        } else {
          const convertedFile = await imageToGrayScale(file, 120)
          values['logo'] = convertedFile
        }
        if (isOfficer) {
          await PUT(`${URL_STORE.OFFICER_STORE}${target}/`, values)
        } else {
          await PUT(URL_STORE.STORE, values);
        }
        Toast.success('สำเร็จ')
        setFile(null);
        fetchData();
      } catch(error) {
        setErrorMessages(error.errorMessages)
      } finally {
        setIsLoading(false)
      }
    }
  })

  const storeTypeDropdown = useDropdownItem({
    url: URL_STORE.STORE_TYPE,
    manual: true,
    text_renderer: (item) => item.name
  });

  const menus = [
    { 
      value: 'general',
      icon: 'building',
      text: 'ข้อมูลร้านค้า',
      errors: ['name', 'address_no', 'sub_district', 'telephone', 'email'],
    },
    { 
      value: 'payment',
      icon: 'money bill alternate outline',
      text: 'การคิดเงิน',
      errors: ['tax_rate', 'tax_type', 'promptpay_account_code', 'bank', 'bank_account_name', 'bank_account_code'],
    },
    {
      value: 'manage_user',
      icon: 'user',
      text: 'จัดการผู้ใช้',
      errors: [],
      permissions: setting.use_staff ? staff.is_manager : true
    }
  ];
  const [errorCount, setErrorCount] = React.useState({
    'general': 0,
    'payment': 0,
  })
  const [file, setFile] = React.useState(null);
  const [activeMenu, setActiveMenu] = React.useState('general');
  const [isLoading, setIsLoading] = React.useState(false);
  const [errorMessages, setErrorMessages] = React.useState(null);
  const {
    provinces,
    districts,
    subDistricts,
    handleProvinceChange,
    handleDistrictChange,
    handleSubDistrictChange,
  } = useAddressForm({ formik });

  const fetchData = async () => {
    setErrorMessages(null);
    setIsLoading(true);
    try {
      storeTypeDropdown.fetchData();
      const response = await GET(target ? `${URL_STORE.OFFICER_STORE}${target}/` : URL_STORE.STORE);
      formik.setValues(response.data)
    } catch(error) {
      setErrorMessages(error.errorMessages)
    } finally {
      setIsLoading(false)
    }
  }

  React.useEffect(() => {
    const newErrorCount = {}
    menus.forEach(menu => {
      newErrorCount[menu.value] = _.get(menu, 'errors', []).reduce((prev, curr) => {
        return prev + (_.get(formik.errors, curr, false) ? 1 : 0)
      }, 0)
    })
    setErrorCount(newErrorCount)
  }, [formik.errors])

  const renderMenuForm = () => {
    switch(activeMenu) {
      case 'general': return (
        <>
          <Header>ข้อมูลทั่วไป</Header>
          <Form.Group widths={isOfficer ? '3' : '2'}>
            <CustomInputField
              isForm
              readOnly={readOnly}
              name='name'
              label='ชื่อร้านค้า'
              icon='home'
              iconPosition='left'
              placeholder='ชื่อร้านค้า'
              value={formik.values.name}
              error={formik.errors.name}
              onChange={formik.handleChange}/>
            {isOfficer && 
              <Form.Select
                name='storeType'
                label='ประเภทร้านค้า'
                search
                options={storeTypeDropdown.response}
                placeholder='ประเภทร้านค้า'
                value={formik.values.template_type}
                error={formik.errors.template_type}
                onChange={(_, data) => {
                  if (!readOnly) {
                    formik.setFieldValue('template_type', data.value)
                  }
                }} />
            }
            <CustomInputField
              isForm
              readOnly={readOnly}
              name='email'
              label='อีเมลร้านค้า (สำหรับส่งรายงาน)'
              icon='mail'
              iconPosition='left'
              placeholder='อีเมลร้านค้า'
              value={formik.values.email}
              error={formik.errors.email}
              onChange={formik.handleChange}/>
            
          </Form.Group>
          <Form.Group widths='3'>
            <CustomInputField
              isForm
              readOnly={readOnly}
              name='branch'
              label='สาขา'
              icon='sitemap'
              iconPosition='left'
              placeholder='สาขา'
              value={formik.values.branch}
              error={formik.errors.branch}
              onChange={formik.handleChange}/>
            <CustomInputField
              isForm
              readOnly={readOnly}
              name='telephone'
              label='เบอร์โทรศัพท์'
              icon='phone'
              iconPosition='left'
              placeholder='เบอร์โทรศัพท์'
              value={formik.values.telephone}
              error={formik.errors.telephone}
              onChange={formik.handleChange}/>
            <CustomInputField
              isForm
              readOnly={readOnly}
              name='tax_id'
              label='เลขที่ประจำตัวผู้เสียภาษี'
              icon='address card'
              iconPosition='left'
              placeholder='เลขที่ประจำตัวผู้เสียภาษี'
              value={formik.values.tax_id}
              error={formik.errors.tax_id}
              onChange={formik.handleChange}/>
          </Form.Group> 
          <Header>ที่อยู่</Header>
          <Form.Group widths='4'>
            <CustomInputField
              isForm
              readOnly={readOnly}
              name='building_name'
              label='ชื่ออาคาร'
              placeholder='ชื่ออาคาร'
              value={formik.values.building_name}
              error={formik.errors.building_name}
              onChange={formik.handleChange}/>
            <CustomInputField
              isForm
              readOnly={readOnly}
              name='room_no'
              label='ห้องเลขที่'
              placeholder='ห้องเลขที่'
              value={formik.values.room_no}
              error={formik.errors.room_no}
              onChange={formik.handleChange}/>
            <CustomInputField
              isForm
              readOnly={readOnly}
              name='floor'
              label='ชั้นที่'
              placeholder='ชั้นที่'
              value={formik.values.floor}
              error={formik.errors.floor}
              onChange={formik.handleChange}/>
            <CustomInputField
              isForm
              readOnly={readOnly}
              name='village_name'
              label='ชื่อหมู่บ้าน'
              placeholder='ชื่อหมู่บ้าน'
              value={formik.values.village_name}
              error={formik.errors.village_name}
              onChange={formik.handleChange}/>
          </Form.Group>
          <Form.Group widths='3'>
            <CustomInputField
              isForm
              readOnly={readOnly}
              name='address_no'
              label='เลขที่'
              placeholder='เลขที่'
              value={formik.values.address_no}
              error={formik.errors.address_no}
              onChange={formik.handleChange}/>
            <CustomInputField
              isForm
              readOnly={readOnly}
              name='moo'
              label='หมู่ที่'
              placeholder='หมู่ที่'
              value={formik.values.moo}
              error={formik.errors.moo}
              onChange={formik.handleChange}/>
            <CustomInputField
              isForm
              readOnly={readOnly}
              name='road'
              label='ถนน'
              placeholder='ถนน'
              value={formik.values.road}
              error={formik.errors.road}
              onChange={formik.handleChange}/>
          </Form.Group>
          <Form.Group widths='2'>
            <Form.Select
              readOnly={readOnly}
              name='province'
              label='จังหวัด'
              search
              options={provinces}
              placeholder='จังหวัด'
              value={formik.values.province}
              error={formik.errors.province}
              onChange={(_, data) => {
                if(!readOnly) {
                  formik.setFieldValue('province', data.value)
                  handleProvinceChange();
                }
              }}/>
            <Form.Select
              readOnly={readOnly}
              name='district'
              label='อำเภอ'
              search
              options={districts}
              placeholder='อำเภอ'
              value={formik.values.district}
              error={formik.errors.district}
              onChange={(_, data) => {
                if(!readOnly) {
                  formik.setFieldValue('district', data.value)
                  handleDistrictChange();
                }
              }}/>
          </Form.Group>
          <Form.Group widths='2'>
            <Form.Select
              readOnly={readOnly}
              name='sub_district'
              label='ตำบล'
              search
              options={subDistricts}
              placeholder='ตำบล'
              value={formik.values.sub_district}
              error={formik.errors.sub_district}
              onChange={(_, data) => {
                if(!readOnly) {
                  formik.setFieldValue('sub_district', data.value)
                  handleSubDistrictChange(data.value);
                }
              }}/>
            <CustomInputField
              isForm
              name='zip_code'
              label='รหัสไปรษณีย์'
              readOnly
              placeholder='รหัสไปรษณีย์'
              value={formik.values.zip_code}/>
          </Form.Group>
        </>
      );
      case 'payment': return (
        <>
          <Header>บัญชีธนาคาร</Header>
          <Form.Group widths='3'>
            <Form.Select
              label='ธนาคาร'
              name='bank'
              search
              clearable
              options={BankChoices}
              placeholder='ธนาคาร'
              value={formik.values.bank}
              error={formik.errors.bank}
              onChange={(_, data) => {
                if(!readOnly) {
                  formik.setFieldValue('bank', data.value)
                }
              }}/>
            <CustomInputField
              isForm
              readOnly={readOnly}
              name='bank_account_name'
              label='ชื่อบัญชีธนาคาร'
              icon='address book'
              iconPosition='left'
              placeholder='ชื่อบัญชีธนาคาร'
              value={formik.values.bank_account_name}
              error={formik.errors.bank_account_name}
              onChange={formik.handleChange}/>
            <CustomInputField
              isForm
              readOnly={readOnly}
              name='bank_account_code'
              label='เลขบัญชีธนาคาร'
              icon='payment'
              iconPosition='left'
              placeholder='เลขบัญชีธนาคาร'
              value={formik.values.bank_account_code}
              error={formik.errors.bank_account_code}
              onChange={formik.handleChange}/>
          </Form.Group>
          <Header>บัญชีพร้อมเพย์</Header>
          <Form.Group widths='2'>
            <CustomInputField
              isForm
              readOnly={readOnly}
              name='promptpay_account_code'
              icon='payment'
              iconPosition='left'
              placeholder='บัญชีพร้อมเพย์'
              value={formik.values.promptpay_account_code}
              error={formik.errors.promptpay_account_code}
              onChange={formik.handleChange}/>
          </Form.Group>
          <Header>ภาษี</Header>
          <Form.Group widths='2'>
            <Form.Select
              name='tax_type'
              label='ประเภทภาษี'
              search
              options={TaxTypeChoices}
              placeholder='ประเภทภาษี'
              value={formik.values.tax_type}
              error={formik.errors.tax_type}
              onChange={(_, data) => {
                if(!readOnly) {
                  formik.setFieldValue('tax_type', data.value)
                }
              }}/>
            { formik.values.tax_type != 'no vat' &&
              <Form.Field>
                <label>อัตราภาษี</label>
                <CustomInputField
                  readOnly={readOnly}
                  name='tax_rate'
                  placeholder='อัตราภาษี'
                  icon='sort numeric up'
                  iconPosition='left'
                  label={{ tag: true, content: '%' }}
                  labelPosition='right'
                  type='number'
                  step='1'
                  min='0'
                  value={formik.values.tax_rate}
                  error={formik.errors.tax_rate}
                  onChange={formik.handleChange}/>
              </Form.Field>
            }
          </Form.Group>
          
          <Header>การใช้งานราคาสินค้าของระบบ</Header>
          <Form.Field 
            toggle
            control={Checkbox}
            label={formik.values.use_template_product ? "เปิด" : "ปิด"}
            checked={formik.values.use_template_product}
            error={formik.errors.use_template_product}
            onChange={(_, data) => {
              formik.setFieldValue("use_template_product", data.checked)
            }}/>

          <Header>การใช้งานลิ้นชักเก็บเงิน</Header>
          <Form.Field 
            toggle
            control={Checkbox}
            label={formik.values.use_cash_box ? "เปิด" : "ปิด"}
            checked={formik.values.use_cash_box}
            error={formik.errors.use_cash_box}
            onChange={(_, data) => {
              formik.setFieldValue("use_cash_box", data.checked)
            }}/>

          <Header>การใช้งานเครื่องพิมพ์</Header>
          <Form.Field 
            toggle
            control={Checkbox}
            label={formik.values.use_printer ? "เปิด" : "ปิด"}
            checked={formik.values.use_printer}
            error={formik.errors.use_printer}
            onChange={(_, data) => {
              formik.setFieldValue("use_printer", data.checked)
            }}/>

          <Header>ตั้งค่าใบเสร็จ</Header>
          <Form.Field label='โลโก้ (ความยาวแนวนอนจะถูกปรับให้เหลือ 120 pixels โดยคงสัดส่วนไว้)' />
          { formik.values.logo ?
            <Image size='small' src={formik.values.logo}/> :
            <Icon name='image outline' color='grey' size='huge'/>
          }
          { !readOnly &&
            <Form.Group widths='2'>
              <CustomFileField
                value={file ? file.name : ''}
                onChange={(file) => setFile(file)}/>
            </Form.Group>
          }
          <CustomInputField
            isForm
            readOnly={readOnly}
            name='pos_id'
            label='รหัสประจำเครื่อง POS'
            icon='certificate'
            iconPosition='left'
            placeholder='รหัสประจำเครื่อง POS'
            value={formik.values.pos_id}
            error={formik.errors.pos_id}
            onChange={formik.handleChange}/>
          <Form.TextArea
            isForm
            readOnly={readOnly}
            name='receipt_header'
            label='ข้อความหัวใบเสร็จ'
            icon='file alternate outline'
            placeholder='ข้อความหัวใบเสร็จ'
            value={formik.values.receipt_header}
            error={formik.errors.receipt_header}
            onFocus={(event) => event.target.select()}
            onChange={formik.handleChange}/>
          <Form.TextArea
            isForm
            readOnly={readOnly}
            name='receipt_footer'
            label='ข้อความท้ายใบเสร็จ'
            icon='file alternate outline'
            placeholder='ข้อความท้ายใบเสร็จ'
            value={formik.values.receipt_footer}
            error={formik.errors.receipt_footer}
            onFocus={(event) => event.target.select()}
            onChange={formik.handleChange}/>
        </>
      );
      case 'manage_user': 
        return (
          <StoreUsers/>
        );
    }
  }

  React.useEffect(() => {
    if (formik.errors) {
      window.scrollTo(0, 0);
    }
  }, [formik.errors])

  React.useEffect(() => {
    fetchData();
  }, [])
  
  return (
    <div>
      <Dimmer active={isLoading} inverted><Loader inverted/></Dimmer>
      { errorMessages && <Message error>{errorMessages}</Message> }
      <Form>
        <Menu attached='top' tabular>
          { menus.filter(menu => menu.permissions != false).map((menu, index ) => (
            <Menu.Item
              key={index}
              name={menu.value}
              active={activeMenu === menu.value}
              onClick={() => setActiveMenu(menu.value)}>
              <Media greaterThanOrEqual='md'>
                <Icon name={menu.icon}/>
              </Media>
              {menu.text}
              { errorCount[menu.value] > 0 && 
                <Label
                  color='red'
                  icon='warning'
                  basic
                  content={errorCount[menu.value]}/>
              }
            </Menu.Item>
          )) }
        </Menu>
        <Segment attached='bottom'>
            {renderMenuForm()}
        </Segment>
        <div style={{ textAlign: 'center' }}>
          { !readOnly && (activeMenu !== "manage_user") &&
            <Form.Button
              color='blue'
              icon='save'
              content='บันทึก'
              size='large'
              onClick={(e, data) => {
                formik.handleSubmit(e, data)
                if (formik.errors) {
                  window.scrollTo(0, 0)
                }
              }}/>
          }
        </div>
      </Form>
    </div>
  )
}
